import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DefaultButton } from "./components/buttons/DefaultButton";
import { Header } from "./components/common/Header";
import { Popup } from "./components/common/Popup";
import { BenefitsSection } from "./components/sections/BenefitsSection";
import { FaqHeader } from "./components/sections/FaqHeader";
import { FaqSection } from "./components/sections/FaqSection";
import { FooterSection } from "./components/sections/FooterSection";
import { GridSection } from "./components/sections/GridSection";
import { HeroesSlider } from "./components/sections/HeroesSlider";
import { HeroesTopSection } from "./components/sections/HeroesTopSection";
import { HowToPopup } from "./components/sections/HowToPopup";
import { JoinDiscordSection } from "./components/sections/JoinDiscordSection";
import { MintNowSection } from "./components/sections/MintNowSection";
import { SectionBlock2 } from "./components/sections/SectionBlock2";
import { DISCORD_URL, OPENSEA_LEARN_URL, COINBASE_URL } from "./config";
import {AgeGate} from "./agegate/AgeGate";

export type ScrollablePoint = "story" | "utility" | "faq";
export type OnNavHandler = (point: ScrollablePoint) => void;

function App() {
    const [howToOpen, setHowToOpen] = useState<boolean>(false);
    const onNav = (point: ScrollablePoint) => {};
    return <>
        <AgeGate/>
    {howToOpen&&<HowToPopup onClose={() => setHowToOpen(false)}/>}
        <Header onNav={onNav}/>
        <HeroesTopSection onHowToOpen={()=>setHowToOpen(true)}/>
        <HeroesSlider/>
        <JoinDiscordSection/>
        <FooterSection onNav={onNav}/>
    </>
}

export default App;
