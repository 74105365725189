import { ReactNode } from "react";


export function DefaultButton(props: {
    children: ReactNode,
    onClick?: ()=>void,
    className?: string,
    rewriteBg?: boolean
}) {
    return <button className={`cursor-pointer text-white 
        ${!props.rewriteBg?'bg-active hover:bg-hover-active active:bg-active':''} 
        text-button py-6 px-6 overflow-hidden rounded-2xl ${props.className||''}
        whitespace-nowrap
        `}
        style={{minWidth: "164px"}}
        onClick={()=>props.onClick?.()}
    >
        {props.children}
    </button>
}

