import { useEffect, useRef } from "react";
import Hero_a from "../../assets/images/heroes_slider/a.png";
import Hero_b from "../../assets/images/heroes_slider/b.png";
import Hero_c from "../../assets/images/heroes_slider/c.png";
import Hero_d from "../../assets/images/heroes_slider/d.png";
import Hero_e from "../../assets/images/heroes_slider/e.png";
import Hero_f from "../../assets/images/heroes_slider/f.png";
import Hero_g from "../../assets/images/heroes_slider/g.png";
import Hero_h from "../../assets/images/heroes_slider/h.png";

export function HeroesSlider() {
    const heroes = [
        Hero_a,
        Hero_b,
        Hero_c,
        Hero_d,
        Hero_e,
        Hero_f,
        Hero_g,
        Hero_h
    ];
    const slider = [...heroes, ...heroes];

    const ref = useRef<HTMLDivElement|null>(null);

    useEffect(()=>{
        const ev = (e: Event) => {
            if (!ref.current) return;
            const el = ref.current;

            let d = (el.getBoundingClientRect().y + el.clientHeight) / (window.innerHeight);
            d = Math.min(d, 1);
            d = Math.max(d, 0);
            d = 1 - d;
            
            el.style.marginLeft = `-${d*100}%`;
        };
        window.addEventListener("scroll", ev);
        return () => window.removeEventListener("scroll", ev);
    }, []);

    return <div className="mt-8 lg:mt-24 overflow-hidden flex gap-8 flex-nowrap" ref={ref}>
        {slider.map((h,i) => <img src={h} key={i} alt="Hero" className="rounded-2xl w-52 h-52 lg:w-96 lg:h-96"/>)}
    </div>;
}