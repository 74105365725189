import { DefaultButton } from "./DefaultButton";
import { ReactComponent as DiscordLogo } from "./../../assets/images/discord.svg";

export function DiscordButton(props: Omit<Parameters<typeof DefaultButton>[0], 'children'>) {
    return <DefaultButton {...props}
        rewriteBg={true}
        className="bg-[#5C69F3] hover:bg-[#5C69F3] active:bg-[#5C69F3] flex items-center gap-2 justify-center">
        <DiscordLogo
            className="h-[20px]"
        />
        <div>Join Discord</div>
    </DefaultButton>
}
